.login {
    width: 691px;
    height: 320px;
    position: fixed;
    left: 32%;
    top: 30%;
    background-color: lightgray;
    text-align: center;
    padding: 2rem;
    border-radius: 1rem    
}

.title {
    display: inline-flex;
    align-items: center;
}

.login button {
    font-size: 20px;
    background-color: gray;
    border-radius: 5px;
    padding: 0.25rem 1rem;
    margin: 20px
}

.login input {
    width: 300px;
    margin: 10px;
    border-radius: 5px;
    padding-left: 5px;
}

.login img{
    margin: 10px
}