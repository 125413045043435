body {
    padding: 0;
    margin: 0;
}

.container {
    /*font-family: 'Comic Sans MS';*/
}  

/* MOBILE VERSION */
@media only screen and (max-width: 767px) {
}

/* TABLET VERSION */
@media only screen and (min-width:768px) and (max-width: 1199px) {    
}

/* PC VERSION */
@media only screen and (min-width:1200px ) {      
}