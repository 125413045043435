

/* MOBILE VERSION */
@media only screen and (max-width: 1199px) {
    header {
        background-color: lightgray;
        font-weight: 800;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        text-align: right;
        display: grid
    }

    header h1 {
        /*color: white;*/
    }

    .container {
        margin: 1rem;
        margin-top: 5rem;
    }

    .menu {
        list-style-type: none;
        padding: 0;
        display:initial
    }

    .menu-hidden {
        list-style-type: none;
        padding: 0;
        display: none
    }

    .menu li {
    }

    header a {
        background-color: rgba(1, 1, 61, 0);
        color: black;
        font-size: 36px;
        font-weight: 400;
    }

    header a:hover {
        color: #a1a1a1;
    }

    .nav-icon {
        height: 2.5rem;
        width: 2.5rem
    }

    .mobile-menu-hamburger {
    }

    .menu-top {
        display: flex;
        justify-content: space-between;
    }
}



/* PC VERSION */
@media only screen and (min-width:1200px ) {
    header {
        background-color: lightgray;
        font-weight: 800;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 18.5rem;
        text-align: right;
        display: grid
    }

        header h1 {
            /*color: white;*/
        }

    .container {
        margin: 1rem;
        margin-right: 9rem;
        margin-left: 21rem;
    }

    .menu {
        list-style-type: none;
        padding: 0;
    }

        .menu li {
        }

    header a {
        background-color: rgba(1, 1, 61, 0);
        color: black;
        font-size: 36px;
        font-weight: 400;
    }

        header a:hover {
            color: #a1a1a1;
        }

    .nav-icon {
        height: 2.5rem;
        width: 2.5rem
    }

    .mobile-menu-hamburger{
        display:none;
    }
}